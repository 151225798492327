.login-logo-container{

    display:flex;
    justify-content:center;
    align-items: center;
}

.right-align-container{

    display:flex;
    justify-content:right;
    align-items: right;
}