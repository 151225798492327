body {
  background: #ecf0f1;
}

.load {
  border: 1px solid #ecf0f1;
  padding: 5px;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(135deg);
  width: 50px;
  height: 50px;
  margin-left: 300px;
  margin: auto;
  display: block;
  margin-top: 300px;
}
.load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;

  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child {
  background:#d67fbf;;
  animation-delay: -1.5s;
}
.load :nth-child(2) {
  background: #964f83;;
  animation-delay: -1s;
}
.load :nth-child(3) {
  background: #7e3f6d;;
  animation-delay: -0.5s;
}
.load :last-child {
  background: #4b2541;;
}

@keyframes spin {
  0%,
  100% {
    transform: translateX(200%);
  }
  25% {
    transform: translateY(200%);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateY(0);
  }
}
